import { AxiosError, AxiosResponse } from 'axios';
import api from './api';
import { CustomerUserModel } from './CustomerUserService';
import { Model, Rest } from './Rest';

export type CustomerSettings = {
  app_key: string;
  app_secret: string;
  ind_use_stock: boolean;
  OC_default_category: string;
  OC_default_supplier: string;
  steps: {
    cCodigo: string;
    cDescricao: string;
    cCodOperacao: string;
  }[];
};
export interface CustomerModel extends Model {
  id?: string;
  name: string;
  document_type: string;
  document: string;
  trade_name?: string;
  state_inscription?: string;
  municipal_inscription?: string;
  zip_code?: string;
  address?: string;
  complement?: string;
  number?: string;
  neighborhood?: string;
  city_id?: number;
  state_id?: number;
  settings: CustomerSettings;
  updated_at: string;
  created_at: string;
  admin?: CustomerUserModel;
  users: CustomerUserModel[];
}

export class CustomerService extends Rest {
  resourceable_as = 'App\\Models\\Customer';

  getDefaultModel(merge?: Record<string, any>): CustomerModel {
    return {
      name: '',
      document_type: '',
      document: '',
      settings: {
        app_key: '',
        app_secret: '',
        ind_use_stock: false,
        OC_default_category: '',
        OC_default_supplier: '',
        steps: [],
      },
      updated_at: '',
      created_at: '',
      users: [],
      ...merge,
    };
  }

  updateSettings(data: Record<any, unknown>): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .post('/me/update-settings', { ...data, _method: 'PUT' })
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  clearDatabase(): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .delete('/me/reset')
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }
}

export default new CustomerService('CustomerService', '/customers');
