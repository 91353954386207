import {
  createContext,
  Dispatch,
  ReactNode,
  Reducer,
  useContext,
  useReducer,
} from 'react';
import { CalculationItem } from 'services/OmieService';
import { StateLinkable } from 'types/Form';
import { statuses } from 'types/General';
import Fuse from 'fuse.js';

type POCalculationFields = 'initial_date' | 'final_date';

type POCalculationState = {
  status: statuses;
  ind_use_stock: boolean;
  isModalVisible: boolean;
  isFiltersVisible: boolean;
  initial_date: Date | string;
  final_date: Date;
  filteredCollection: CalculationItem[];
  productCollection: CalculationItem[];
  fuse?: Fuse<CalculationItem>;
};

type POCalculationActions = {
  type: 'setModalVisibility' | 'setFiltersVisibility' | 'changeField' | 'setStatus' | 'setCollection' | 'setFilteredCollection';
  data: any;
};

type POCalculationContextType = {
  state: POCalculationState;
  dispatch: Dispatch<POCalculationActions>;
  link: (field: POCalculationFields) => StateLinkable;
};

const POCalculationContext = createContext({});

function POCalculationReducer(
  state: POCalculationState,
  action: POCalculationActions,
): POCalculationState {
  switch (action.type) {
    case 'setModalVisibility':
      return { ...state, isModalVisible: action.data };
    case 'setFiltersVisibility':
      return { ...state, isFiltersVisible: action.data };
    case 'changeField':
      return { ...state, [action.data.field]: action.data.value };
    case 'setStatus':
      return { ...state, status: action.data };
    case 'setFilteredCollection':
      return { ...state, filteredCollection: action.data };
    case 'setCollection':
      return {
        ...state,
        ind_use_stock: action.data.ind_use_stock,
        filteredCollection: action.data.productCollection,
        productCollection: action.data.productCollection,
        fuse: new Fuse(action.data.productCollection, {
          keys: [
            'codigo_produto',
            'descricao',
            'tipoItem',
            'estoque_atual',
            'qty_em_producao',
            'qty_em_pedidos_de_compra',
            'quantidade_vendida',
            'quantidade_vendida_como_insumo',
            'estoque_minimo',
            'balance',
          ],
          useExtendedSearch: true,
          threshold: 0.2,
        }),
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  // return state;
}

export function POCalculationProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer<
    Reducer<POCalculationState, POCalculationActions>
  >(POCalculationReducer, {
    status: 'idle',
    ind_use_stock: false,
    isModalVisible: false,
    isFiltersVisible: false,
    initial_date: '',
    final_date: new Date(),
    filteredCollection: [],
    productCollection: [],
  });

  function onChangeField(field: POCalculationFields, value: Date | null) {
    dispatch({
      type: 'changeField',
      data: {
        field,
        value,
      },
    });
  }

  function link(field: POCalculationFields): StateLinkable {
    return {
      name: field,
      value: state[field],
      onChange: (e: any) => {
        onChangeField(field, e.target.value);
      },
    };
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <POCalculationContext.Provider value={ { state, dispatch, link } }>
      {children}
    </POCalculationContext.Provider>
  );
}

export function usePOCalculation(): POCalculationContextType {
  const context = useContext<any>(POCalculationContext);
  if (context.state === undefined) {
    throw new Error(
      'usePOCalculation must be used within a POCalculationProvider',
    );
  }
  return context;
}
