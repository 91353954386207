import { AxiosError, AxiosResponse } from 'axios';
import api from './api';

export type OmieBoolean = 'S' | 'N';
export type OmieProductType = '00'
| '01'
| '02'
| '03'
| '04'
| '05'
| '06'
| '07'
| '08'
| '09'
| '10'
| '99';

export type CalculationOrder = {
  codigo_cliente: number;
  codigo_pedido: number;
  codigo_produto: number;
  numero_pedido: number;
  descricao: string;
  feedstock_for: number | null;
  ind_feedstock: boolean;
  nome_fantasia: string;
  data_previsao: string;
  quantidade_vendida: number;
  razao_social: string;
  tipoItem: OmieProductType;
}

export type CalculationItem = {
  codigo_produto: number,
  descricao: string,
  estoque_atual: number,
  quantidade_vendida: number,
  codigo: string,
  tipoItem: OmieProductType
  qty_em_producao: number,
  qty_em_pedidos_de_compra: number,
  qtd_em_nfes_recebidas: number,
  possui_malha: boolean,
  quantidade_vendida_como_insumo: number,
  estoque_minimo: number,
  sobra: number,
  compra_ou_producao: number,
  saldo: number,
  orders: CalculationOrder[]
}

export type Etapa = {
  cCodigo: string;
  cDescrPadrao: string;
  cDescricao: string;
}
export type EtapaFaturamento = {
  cCodOperacao: string;
  cDescOperacao: string;
  etapas: Etapa[]
}

export type OmieCategory = {
  categoria_superior: string;
  codigo: string;
  codigo_dre: string;
  conta_despesa: OmieBoolean;
  conta_inativa: OmieBoolean;
  conta_receita: OmieBoolean;
  dadosDRE: {
    codigoDRE: string;
    descricaoDRE: string;
    naoExibirDRE: OmieBoolean;
    nivelDRE: number;
    sinalDRE: '-' | '+';
    totalizaDRE: OmieBoolean;
  };
  definida_pelo_usuario: OmieBoolean;
  descricao: string;
  descricao_padrao: string;
  id_conta_contabil: string;
  nao_exibir: OmieBoolean;
  natureza: string;
  tag_conta_contabil: string;
  totalizadora: OmieBoolean;
  transferencia: OmieBoolean;
}

export type OmieCustomer = {
  bairro: string;
  bloquear_faturamento: OmieBoolean;
  cep: string;
  cidade: string;
  cidade_ibge: string;
  cnpj_cpf: string;
  codigo_cliente_integracao: string;
  codigo_cliente_omie: number;
  codigo_pais: string;
  complemento: string;
  contato: string;
  dadosBancarios: {
    agencia: string;
    codigo_banco: string;
    conta_corrente: string;
    doc_titular: string;
    nome_titular: string;
    transf_padrao: OmieBoolean
  };
  email: string;
  endereco: string;
  enderecoEntrega: {
    entBairro: string;
    entCEP: string;
    entCidade: string;
    entEndereco: string;
    entEstado: string;
    entIE: string;
    entRazaoSocial: string;
    entTelefone: string;
  };
  endereco_numero: string;
  estado: string;
  exterior: OmieBoolean;
  inativo: OmieBoolean;
  info: {
    cImpAPI: OmieBoolean;
    dAlt: string;
    dInc: string;
    hAlt: string;
    hInc: string;
    uAlt: string;
    uInc: string;
  },
  inscricao_estadual: string;
  inscricao_municipal: string;
  nome_fantasia: string;
  optante_simples_nacional: OmieBoolean;
  pessoa_fisica: OmieBoolean;
  produtor_rural: OmieBoolean;
  razao_social: string;
  recomendacoes: {
    codigo_transportadora: number;
    gerar_boletos: OmieBoolean;
  },
  tags: [
    {
      tag: string;
    }
  ];
  telefone1_ddd: string;
  telefone1_numero: string;
  telefone2_ddd: string;
  telefone2_numero: string;
  valor_limite_credito: number;
}

export class OmieService {
  protected serviceName = 'OmieService';

  protected resoruceRoute = '/omie';

  public static PRODUCT_TYPE_MAP = {
    '00': { id: '00', name: 'Revenda' },
    '01': { id: '01', name: 'Matéria Prima' },
    '02': { id: '02', name: 'Embalagem' },
    '03': { id: '03', name: 'Prod Processo' },
    '04': { id: '04', name: 'Prod Acabado' },
    '05': { id: '05', name: 'Subproduto' },
    '06': { id: '06', name: 'Prod Intermed' },
    '07': { id: '07', name: 'Uso e Consumo' },
    '08': { id: '08', name: 'Ativo' },
    '09': { id: '09', name: 'Serviços' },
    10: { id: '10', name: 'Outros Insumos' },
    99: { id: '99', name: 'Outras' },
  };

  public static PRODUCT_TYPE_OPTIONS = [
    { id: '00', name: 'Revenda' },
    { id: '01', name: 'Matéria Prima' },
    { id: '02', name: 'Embalagem' },
    { id: '03', name: 'Prod Processo' },
    { id: '04', name: 'Prod Acabado' },
    { id: '05', name: 'Subproduto' },
    { id: '06', name: 'Prod Intermed' },
    { id: '07', name: 'Uso e Consumo' },
    { id: '08', name: 'Ativo Imobilizado' },
    { id: '09', name: 'Serviços' },
    { id: '10', name: 'Outros Insumos' },
    { id: '99', name: 'Outras' },
  ];

  public static PRODUCTION_CODES = [
    '03',
    '04',
    '05',
    '06',
  ];

  public static BOUGHT_CODES = [
    '00',
    '01',
    '02',
    '07',
    '08',
    '09',
    '10',
    '99',
  ];

  public static isProduction(value: string): boolean {
  // public static isProduction(item: CalculationItem | CalculationHistoryItemModel): boolean {
    // const field = item.tipoItem === undefined ? 'vendor_type' : 'tipoItem';
    return this.PRODUCTION_CODES.indexOf(value) > -1;
  }

  getCalculation(data: {
    final_date: Date | string,
    initial_date?: Date | string
  }): Promise<AxiosResponse<{
    ind_use_stock: boolean,
    products: CalculationItem[],
  }>> {
    return new Promise((resolve, reject) => {
      api
        .post(`${this.resoruceRoute}/calculate`, data)
        .then((
          response: AxiosResponse<{
            ind_use_stock: boolean,
            products: CalculationItem[],
          }>,
        ) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  listarEtapasFaturamento() : Promise<AxiosResponse<EtapaFaturamento>> {
    return new Promise((resolve, reject) => {
      api
        .post(`${this.resoruceRoute}/listar-etapas-faturamento`)
        .then((
          response: AxiosResponse<EtapaFaturamento>,
        ) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  listarFornecedores() : Promise<AxiosResponse<OmieCustomer[]>> {
    return new Promise((resolve, reject) => {
      api
        .post(`${this.resoruceRoute}/listar-fornecedores`)
        .then((
          response: AxiosResponse<OmieCustomer[]>,
        ) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  listarCategorias() : Promise<AxiosResponse<OmieCategory[]>> {
    return new Promise((resolve, reject) => {
      api
        .post(`${this.resoruceRoute}/listar-categorias`)
        .then((
          response: AxiosResponse<OmieCategory[]>,
        ) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }
}

export default new OmieService();
