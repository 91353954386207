import { AxiosResponse } from 'axios';
import {
  ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import { URLSearchParamsInit } from 'react-router-dom';
import { RestInterface } from 'services/Rest';
import { statuses } from 'types/General';
import {
  FilterLinkable,
  FilterLinkCallable,
  FilterOperands,
  PaginationProps,
} from 'types/List';

let searchTimeoutId: number | undefined;

export function useList<M>(
  service: RestInterface,
  searchParams: URLSearchParams,
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined
  ) => void,
): {
  status: statuses;
  collection: M[];
  pagination: PaginationProps;
  isFiltersVisible: boolean;
  setStatus: (status: statuses) => void;
  fetchCollection: () => void;
  onPaginate: (page: number) => void;
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  onSort: (sort: string) => void;
  linkFilter: FilterLinkCallable;
  changeFilter: (field: string, value: any, operand?: string | null) => void;
  toggleFilters: (isVisible: boolean) => void;
} {
  const [status, setStatus] = useState<statuses>('idle');
  const [collection, setCollection] = useState<M[]>([]);
  const [isFiltersVisible, toggleFilters] = useState<boolean>(false);
  const [querystring, setQuerystring] = useState<string>(
    searchParams.toString(),
  );
  const [pagination, setPagination] = useState<PaginationProps>({
    currentPage: 1,
    total: 1,
    offset: 15,
  });

  function onPaginate(page: number) {
    searchParams.set('page', `${page}`);
    setQuerystring(searchParams.toString());
  }

  function onSearch(e: ChangeEvent<HTMLInputElement>) {
    window.clearTimeout(searchTimeoutId);
    setStatus('pending');
    searchParams.set('page', '1');
    if (e.target.value) {
      searchParams.set('q', e.target.value);
    } else {
      searchParams.delete('q');
    }
    setSearchParams(searchParams);
    searchTimeoutId = window.setTimeout(() => {
      setQuerystring(searchParams.toString());
    }, 320);
  }

  function onSort(sort: string) {
    searchParams.set('sort', sort);
    setQuerystring(searchParams.toString());
  }

  const fetchCollection = useCallback(() => {
    setStatus('pending');
    const sp = new URLSearchParams(querystring);
    service
      .get(sp)
      .then((res: AxiosResponse) => {
        setPagination(
          sp.has('page')
            ? {
              currentPage: res.data.meta.current_page,
              offset: parseInt(res.data.meta.per_page, 10),
              total: res.data.meta.total,
            }
            : { currentPage: 1, total: 1, offset: 15 },
        );
        setCollection(res.data.data || res.data);
        setStatus('success');
      })
      .catch((err) => {
        setCollection([]);
        setStatus('error');
      });
  }, [service, querystring]);

  useEffect(() => {
    fetchCollection();
  }, [fetchCollection]);

  /*
  |--------------------------------------------------------------------------
  | Link Filters
  |--------------------------------------------------------------------------
  */

  function changeFilter(field: string, value: any, operand?: string | null) {
    window.clearTimeout(searchTimeoutId);
    setStatus('pending');
    searchParams.set('page', '1');
    if (value) {
      searchParams.set(`filter[${field}]`, value);
    } else {
      searchParams.delete(`filter[${field}]`);
    }
    setSearchParams(searchParams);
    searchTimeoutId = window.setTimeout(() => {
      setQuerystring(searchParams.toString());
    }, 320);
  }

  function linkFilter(
    field: string,
    operand?: FilterOperands,
    hookParse?: ((value: string) => string) | null,
  ): FilterLinkable {
    const value: string = searchParams.get(`filter[${field}]`) || '';
    // if (operand && typeof value === 'string') {
    //   value = value.replace(operand, '');
    // }

    // if (hookParse) {
    //   value = hookParse(value);
    // }

    return {
      name: field,
      value,
      onChange: (e: any) => {
        const cValue = e.target.value || '';
        // if (hookChange) {
        //     value = hookChange(value);
        // }
        changeFilter(field, cValue, operand);
      },
    };
  }

  return {
    status,
    collection,
    pagination,
    isFiltersVisible,
    setStatus,
    fetchCollection,
    onPaginate,
    onSearch,
    onSort,
    linkFilter,
    changeFilter,
    toggleFilters,
  };
}
