import { ConfigurationDashboard } from 'views/configurations/Dashboard';
import { DefaultLayout } from './layout/DefaultLayout';
import { Home } from './Home';
import { Login } from './auth/Login';
import { PasswordRecovery } from './auth/PasswordRecovery';
import { PasswordReset } from './auth/PasswordReset';
import { Error404 } from './layout/Error404';
import { OmieCalculation } from './Omie/Calculation';
import { CalculationHistoryList } from './calculationHistory/List';
import { OrderHistoryItemList } from './OrderHistoryItem/List';
import { CalculationHistoryView } from './calculationHistory/View';

// import { ProductsList } from 'views/product/List';
// import { ProductsCreate } from 'views/product/Create';
// import { ProductsEdit } from 'views/product/Edit';
// import { CustomersList } from 'views/customer/List';
// import { CustomersCreate } from 'views/customer/Create';
// import { CustomersEdit } from 'views/customer/Edit';
// import { OrdersList } from 'views/order/List';
// import { OrdersCreate } from 'views/order/Create';
// import { OrdersEdit } from 'views/order/Edit';

// import { SkusList } from 'views/sku/List';
// import { ProductionOrderCalculation } from './productionOrder/Calculation';

export const Views = {
  DefaultLayout,
  Home,

  Login,
  PasswordRecovery,
  PasswordReset,

  OmieCalculation,
  CalculationHistoryList,
  OrderHistoryItemList,
  CalculationHistoryView,
  // ProductionOrderList,
  // OrdersList, OrdersCreate, OrdersEdit,
  // ProductsList, ProductsCreate, ProductsEdit,
  // CustomersList, CustomersCreate, CustomersEdit,
  // SkusList,
  ConfigurationDashboard,

  Error404,
};
