import { Config } from 'configs';
import {
  FormEvent, useEffect, useState,
} from 'react';
import {
  Button, Form, ListGroup, ListGroupItem, Modal, Spinner,
} from 'react-bootstrap';
import { CustomerModel } from 'services/CustomerService';
import NotificationService from 'services/NotificationService';
import OmieService, { OmieCategory } from 'services/OmieService';

export function CategoriesConfiguration(
  {
    customer,
    submit,
  } : {
     customer: CustomerModel,
     submit: (data: any) => void
  },
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const [OCDefaultCategory, setOCDefaultCategory] = useState<string>('');
  const [categorias, setCategorias] = useState<OmieCategory[] | null>(null);

  useEffect(() => {
    setOCDefaultCategory(customer.settings.OC_default_category);
  }, [customer.settings.OC_default_category]);

  function openModal() {
    setModalVisibility(true);
    setLoading(true);
    OmieService.listarCategorias()
      .then((res) => {
        // const fuse = new Fuse(res.data, {
        //   keys: [
        //     'codigo',
        //     'descricao',
        //   ],
        //   useExtendedSearch: true,
        //   threshold: 0.2,
        // });
        res.data.sort((a: OmieCategory, b: OmieCategory) => a.descricao.localeCompare(b.descricao));
        setCategorias(res.data);
      })
      .catch((err) => {
        NotificationService.danger('Ops, não conseguimos buscar as categorias.');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function closeModal() {
    setModalVisibility(false);
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    submit({ OC_default_category: OCDefaultCategory });
  }

  function onSelect(value: string) {
    setOCDefaultCategory(value);
  }

  function renderForm() {
    if (loading) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="grow" />
        </div>
      );
    }
    return (
      <Form onSubmit={ onSubmit }>
        <ListGroup className="custom-scrollbar" style={ { maxHeight: '80vh', overflow: 'auto', paddingRight: '5px' } }>
          { categorias && categorias.map((category) => {
            const checked = category.codigo === OCDefaultCategory;
            return (
              <ListGroupItem variant={ checked ? 'primary' : '' } className="cursor-pointer" onClick={ onSelect.bind(null, category.codigo) } key={ category.codigo }>
                <Form.Check
                  label={ `${category.codigo} | ${category.descricao}` }
                  type="radio"
                  checked={ checked }
                  readOnly
                />
              </ListGroupItem>
            );
          }) }

        </ListGroup>
        <hr />
        <div className="d-flex gap-3 justify-content-end">
          <Button
            onClick={ closeModal }
            variant="white"
          >
            Fechar
          </Button>
          <Button
            onClick={ closeModal }
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </Form>
    );
  }

  return (
    <>
      <div className="text-uppercase mb-2">
        <strong>Selecionar Categoria Padrão</strong>
      </div>
      <div>
        <p>
          Selecione uma categoria padrão para associar os
          Pedidos de Compra quando eles forem gerados pelo
          {' '}
          { Config.app.name }
        </p>
        <Button
          onClick={ openModal }
          className="text-nowrap"
        >
          <span>Selecionar</span>
        </Button>
      </div>

      <Modal show={ isModalVisible } onHide={ closeModal }>
        <Modal.Header>
          <Modal.Title>Selecione uma categoria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { renderForm() }
        </Modal.Body>
      </Modal>
    </>
  );
}
